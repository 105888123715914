import { ExclamationCircleFilled } from "@ant-design/icons";
import { NetSuiteAppInfo } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ConfirmDeleteDataSourceAppInfoModal,
  Form,
  ImportTypeIcon,
  Input,
  LoaderWithPerfTimings,
  NetSuiteRESTLetConfigureModal,
  Tooltip,
  WheelIcon,
} from "../components";
import { COLORS } from "../constants";
import { AppState } from "../store";
import { useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";

const Spacer = styled.div`
  height: 8px;
`;

const StyledText = styled.div`
  color: ${COLORS.black45};
  margin: 4px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const Container = styled.div`
  padding: 25px 30px;
  max-width: 660px;
`;

const StyledHeader = styled(Header)`
  h1 {
    margin: 0 10px;
  }

  .helpGuide {
    display: flex;
    align-items: center;
    margin-left: auto !important;
  }
`;

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const FormGroup = styled.div`
  label {
    font-weight: bold;
    margin: 20px 12px 8px 2px;
  }
  margin-bottom: 12px;
`;

const RESTLetConfigButton = styled(Button)`
  padding: 4px 0;

  &.warningMessage {
    margin-left: 22px;
  }
`;

const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
  color: ${COLORS.orange2};
  margin-right: 8px;
`;

const WarningMessageDescription = styled.div`
  margin-left: 22px;
`;

const StyledButton = styled(Button)`
  width: 160px;
`;

const HelpGuide = () => {
  return (
    <div className="helpGuide">
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a href="https://coefficient.io/help-center/netsuite" target="_blank">
          Help Guide
        </a>
      </Button>
    </div>
  );
};

const RESTLET_OUTDATED_SEARCH_PARAM_KEY = "restletOutdated";

export const NetSuiteOAuthManageSettings = () => {
  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const track = useTrack();

  const history = useHistory();

  const { accountId } = useParams<{ accountId: string }>();

  const searchParams = new URLSearchParams(location.search);

  const isRestletOutdated = searchParams.has(RESTLET_OUTDATED_SEARCH_PARAM_KEY);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isRESTLetConfigurationModalVisible, setIsRESTLetConfigurationModalVisible] =
    useState(isRestletOutdated);

  const [_, contextHolder] = message.useMessage();

  const paths = history.location.pathname.split("/");

  const backUrl = paths.splice(0, paths.length - 1).join("/");

  useEffect(() => {
    document.title = "NetSuite OAuth Settings - Coefficient Workspace";
    track("workspace_netsuite_oauth_settings_viewed");
  }, []);

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const {
    data: netSuiteAppInfo,
    isFetching: isFetchingAppInfo,
    error: errorFetchingAppInfo,
  } = useQuery([QueryCacheKey.NETSUITE_APP_INFO, accountId], () => api.listNetSuiteAppInfo(), {
    select: data => data.data.apps_list.filter(app => app.account_id === accountId)[0],
  });

  const deleteAppInfoMutation = useMutation(
    (appInfo: NetSuiteAppInfo) =>
      api.deleteNetSuiteAppInfo({
        delete_net_suite_app_info_request: {
          account_id: appInfo.account_id ?? "",
        },
      }),
    {
      onSuccess: () => {
        history.goBack();
        message.success("NetSuite OAuth configuration deleted");
        queryClient.invalidateQueries([QueryCacheKey.NETSUITE_APP_INFO]);
        queryClient.invalidateQueries([QueryCacheKey.NETSUITE_APP_INFO, accountId]);
      },
      onError: () => message.error("Something went wrong. Please try again."),
    }
  );

  const renderContent = (children: React.ReactNode) => {
    return (
      <Container>
        <Button
          type="link"
          style={{ marginBottom: "8px", marginLeft: "-12px" }}
          onClick={() => history.push(backUrl)}
        >
          Back
        </Button>
        <StyledHeader>
          <ImportTypeIcon type="netsuite" />
          <h1>NetSuite OAuth</h1>
          <HelpGuide />
        </StyledHeader>
        {contextHolder}
        {children}
      </Container>
    );
  };

  if (
    clientFlags?.admin_emails &&
    clientFlags?.admin_emails.length > 0 &&
    !clientFlags?.admin_emails?.includes(clientFlags.email)
  ) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="You need to be an admin to access this page"
        description="Please contact your domain admin for configuring or managing NetSuite accounts."
      />
    );
  }

  if (deleteAppInfoMutation.isLoading || isFetchingAppInfo) {
    return renderContent(<LoaderWithPerfTimings name="NetSuiteOAuthSettings" />);
  }

  if (errorFetchingAppInfo) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Error loading NetSuite OAuth Settings"
        description="Please try refreshing this page, or contact support@coefficient.io"
      />
    );
  }

  if (!netSuiteAppInfo) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Could not find NetSuite OAuth Settings for this account"
        description="It's possible that the settings have been deleted."
      />
    );
  }

  return renderContent(
    <Content>
      {isRestletOutdated ? (
        <StyledAlert
          type="warning"
          action={
            <Space direction="vertical">
              <div>
                <StyledExclamationCircleFilled className="icon" />
                <strong>RESTLet version is outdated</strong>
              </div>
              <WarningMessageDescription>
                The RESTLet version is outdated and some features may not work. Please update the
                RESTLet in NetSuite.
              </WarningMessageDescription>
              <RESTLetConfigButton
                className="warningMessage"
                type="link"
                onClick={() => setIsRESTLetConfigurationModalVisible(true)}
              >
                Manage RESTLet Script and External URL
              </RESTLetConfigButton>
            </Space>
          }
        />
      ) : (
        <StyledAlert
          type="info"
          showIcon
          message="NetSuite OAuth has been configured for your domain"
          description="Connect to NetSuite using the Coefficient sidebar in Google Sheets / Excel."
        />
      )}

      <Form style={{ marginTop: "40px" }}>
        <Spacer />
        <FormGroup>
          <Tooltip title="Account ID can not be edited">
            <label>Account ID</label>
            <Input disabled value={netSuiteAppInfo.account_id} />
          </Tooltip>
        </FormGroup>
        <Spacer />
        <FormGroup>
          <label>External URL</label>
          <StyledText>{netSuiteAppInfo.external_url}</StyledText>
          <RESTLetConfigButton
            type="link"
            onClick={() => setIsRESTLetConfigurationModalVisible(true)}
          >
            Edit External URL
          </RESTLetConfigButton>
        </FormGroup>
        <Spacer />
        <StyledButton onClick={() => setIsDeleteModalVisible(true)}>Delete</StyledButton>
      </Form>
      <ConfirmDeleteDataSourceAppInfoModal
        open={isDeleteModalVisible}
        isLoading={deleteAppInfoMutation.isLoading}
        dataImportCount={netSuiteAppInfo.data_import_count}
        dataSourceCount={netSuiteAppInfo.data_source_count}
        onOk={() => {
          setIsDeleteModalVisible(false);
          deleteAppInfoMutation.mutate(netSuiteAppInfo);
        }}
        onCancel={() => setIsDeleteModalVisible(false)}
        dataSourceType="netsuite"
      />
      <NetSuiteRESTLetConfigureModal
        open={isRESTLetConfigurationModalVisible}
        onSuccess={() => {
          searchParams.delete(RESTLET_OUTDATED_SEARCH_PARAM_KEY);

          history.replace({ ...history.location, search: searchParams.toString() });

          setIsRESTLetConfigurationModalVisible(false);
        }}
        onCancel={() => setIsRESTLetConfigurationModalVisible(false)}
        accountId={netSuiteAppInfo.account_id ?? ""}
        externalURL={netSuiteAppInfo.external_url}
      />
    </Content>
  );
};
