import { DomainSettings } from "@coeff/api-admin-ext";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAdminExtSettings } from "../../../api";
import { Button, Form, List, LoaderWithPerfTimings, CaretRightBlack } from "../../../components";
import { COLORS } from "../../../constants";
import { AppState } from "../../../store";
import { pluralize, useTrack } from "../../../utils";

import { AdminSettingsConfigureModal, SettingsType } from "./AdminSettingsConfigureModal";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const { Item } = List;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black2};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
    padding: 4px 12px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      background-color: ${COLORS.black4} !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black85};
      font-weight: bold;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black45};
      font-size: 12px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;

const Container = styled.div`
  max-width: 660px;
`;

const FormGroup = styled.div`
  h3 {
    font-weight: bold;
    margin: 0px 12px 8px 2px;
  }

  margin-top: 12px;
`;

const StyledButton = styled(Button)`
  padding: 20px 12px;

  &.ant-btn-text {
    font-weight: lighter;
    &:hover {
      background-color: inherit;
    }
  }
`;

const StyledSummaryText = styled.div`
  color: ${COLORS.black45};

  &.underline {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px 12px;
`;

const getconfigSummaryText = (
  settingsType: SettingsType,
  adminExtSettings: DomainSettings | undefined,
  isSingleDomain: boolean
) => {
  if (!adminExtSettings) {
    return "";
  }

  switch (settingsType) {
    case "writeback_settings":
      return Boolean(adminExtSettings?.domain_level_writeback_settings?.is_disabled)
        ? "Disabled"
        : adminExtSettings?.domain_level_writeback_settings?.domains_list
        ? `${
            adminExtSettings.domain_level_writeback_settings?.domains_list.length
          } domain${pluralize(
            adminExtSettings?.domain_level_writeback_settings?.domains_list.length
          )} ${
            adminExtSettings.domain_level_writeback_settings.allow_or_disallow_domains === "allow"
              ? "allowed"
              : "not allowed"
          }`
        : adminExtSettings?.domain_level_writeback_settings?.user_emails_list
        ? `${
            adminExtSettings.domain_level_writeback_settings.user_emails_list.length
          } user${pluralize(
            adminExtSettings?.domain_level_writeback_settings?.user_emails_list.length
          )} ${
            adminExtSettings.domain_level_writeback_settings.allow_or_disallow_users === "allow"
              ? "allowed"
              : "not allowed"
          }`
        : isSingleDomain
        ? "All users allowed"
        : "All domains and users allowed";
    case "slack_or_email_settings":
      return Boolean(adminExtSettings?.slack_or_email_alerts_settings?.is_disabled)
        ? "Disabled"
        : adminExtSettings?.slack_or_email_alerts_settings?.domains_list
        ? `${adminExtSettings.slack_or_email_alerts_settings.domains_list.length} domain${pluralize(
            adminExtSettings?.slack_or_email_alerts_settings?.domains_list.length
          )} ${
            adminExtSettings.slack_or_email_alerts_settings.allow_or_disallow_domains === "allow"
              ? "allowed"
              : "not allowed"
          }`
        : isSingleDomain
        ? "Enabled"
        : "All domains allowed";
    case "gpt_settings":
      return Boolean(adminExtSettings?.gpt_features_settings?.is_disabled)
        ? "Disabled"
        : adminExtSettings?.gpt_features_settings?.domains_list
        ? `${adminExtSettings.gpt_features_settings.domains_list.length} domain${pluralize(
            adminExtSettings?.gpt_features_settings?.domains_list.length
          )} ${
            adminExtSettings.gpt_features_settings.allow_or_disallow_domains === "allow"
              ? "allowed"
              : "not allowed"
          }`
        : isSingleDomain
        ? "Enabled"
        : "All domains allowed";
    case "cloud_table_settings":
      return Boolean(adminExtSettings?.cloud_table_settings?.is_disabled)
        ? "Disabled"
        : adminExtSettings?.cloud_table_settings?.domains_list
        ? `${adminExtSettings.cloud_table_settings.domains_list.length} domain${pluralize(
            adminExtSettings?.cloud_table_settings?.domains_list.length
          )} ${
            adminExtSettings.cloud_table_settings.allow_or_disallow_domains === "allow"
              ? "allowed"
              : "not allowed"
          }`
        : isSingleDomain
        ? "Enabled"
        : "All domains allowed";
    case "connection_sharing_settings":
      return Boolean(adminExtSettings?.connection_sharing_settings?.is_disabled)
        ? "Disabled"
        : "Enabled";
    case "allowed_data_sources_settings":
      return adminExtSettings?.allowed_data_sources_settings?.data_sources_list
        ? `${
            adminExtSettings.allowed_data_sources_settings.data_sources_list.length
          } data source${pluralize(
            adminExtSettings?.allowed_data_sources_settings?.data_sources_list.length
          )} ${
            adminExtSettings.allowed_data_sources_settings.allow_or_disallow_data_sources ===
            "allow"
              ? "allowed"
              : "not allowed"
          }`
        : "All data sources allowed";
    default:
      return "";
  }
};

export const AdminSettings: React.FC = () => {
  const track = useTrack();

  const [showAdminSettingsConfigureModal, setShowAdminSettingsConfigureModal] = useState(false);

  const [currentSettingType, setCurrentSettingType] = useState<SettingsType>();

  const [_, contextHolder] = message.useMessage();

  const { clientFlags: clientFlags, domains_in_domain_family: domains_in_domain_family } =
    useSelector((state: AppState) => ({
      domains_in_domain_family: state.app.domains_in_domain_family,
      clientFlags: state.app.clientFlags,
    }));

  const { data: adminExtSettings, isLoading: isLoadingAdminExtSettings } = useAdminExtSettings({
    enabled: Boolean(clientFlags?.is_gsuite_domain_admin),
  });

  const renderContent = (children: React.ReactNode) => {
    return (
      <Container>
        {contextHolder}
        {children}
      </Container>
    );
  };

  if (isLoadingAdminExtSettings) {
    return renderContent(<LoaderWithPerfTimings name="WorkspaceAdminSettings" />);
  }

  // For Enabling SSO config for single domains, we added them to a domain family with a dummy domain
  // that starts with "coeff." and is not a real domain.
  const isSingleDomain =
    domains_in_domain_family.filter(domain => !domain.toLowerCase().startsWith("coeff.")).length <
    2;

  return renderContent(
    <Content>
      <Form>
        <FormGroup>
          <h3>Features</h3>
        </FormGroup>
      </Form>
      {Boolean(adminExtSettings?.show_writeback_settings_in_ui) && (
        <StyledItem onClick={() => null}>
          <Item.Meta
            title="Exports"
            description="Allow users to export data from Sheets to other systems"
          />
          <StyledButton
            type="text"
            onClick={() => {
              setCurrentSettingType("writeback_settings");
              setShowAdminSettingsConfigureModal(true);
            }}
          >
            <StyledSummaryText
              className={
                Boolean(adminExtSettings?.domain_level_writeback_settings?.is_disabled)
                  ? ""
                  : "underline"
              }
            >
              {getconfigSummaryText("writeback_settings", adminExtSettings, isSingleDomain)}
            </StyledSummaryText>
            <CaretRightBlack />
          </StyledButton>
        </StyledItem>
      )}

      {Boolean(adminExtSettings?.show_alerts_settings_in_ui) && (
        <StyledItem onClick={() => null}>
          <Item.Meta
            title="Slack or email alerts"
            description="Allow users to create Slack or email alerts"
          />
          <StyledButton
            type="text"
            onClick={() => {
              setCurrentSettingType("slack_or_email_settings");
              setShowAdminSettingsConfigureModal(true);
            }}
          >
            <StyledSummaryText
              className={
                Boolean(adminExtSettings?.slack_or_email_alerts_settings?.is_disabled)
                  ? ""
                  : "underline"
              }
            >
              {getconfigSummaryText("slack_or_email_settings", adminExtSettings, isSingleDomain)}
            </StyledSummaryText>
            <CaretRightBlack />
          </StyledButton>
        </StyledItem>
      )}

      <StyledItem onClick={() => null}>
        <Item.Meta
          title="GPT features"
          description="Allow users to access AI features powered by OpenAI's ChatGPT"
        />
        <StyledButton
          type="text"
          onClick={() => {
            setCurrentSettingType("gpt_settings");
            setShowAdminSettingsConfigureModal(true);
          }}
        >
          <StyledSummaryText
            className={
              Boolean(adminExtSettings?.gpt_features_settings?.is_disabled) ? "" : "underline"
            }
          >
            {getconfigSummaryText("gpt_settings", adminExtSettings, isSingleDomain)}
          </StyledSummaryText>
          <CaretRightBlack />
        </StyledButton>
      </StyledItem>

      <StyledItem onClick={() => null}>
        <Item.Meta
          title="Cloud datasets"
          description="Allow users to save spreadsheet data Coefficient's secure cloud"
        />
        <StyledButton
          type="text"
          onClick={() => {
            setCurrentSettingType("cloud_table_settings");
            setShowAdminSettingsConfigureModal(true);
          }}
        >
          <StyledSummaryText
            className={
              Boolean(adminExtSettings?.cloud_table_settings?.is_disabled) ? "" : "underline"
            }
          >
            {getconfigSummaryText("cloud_table_settings", adminExtSettings, isSingleDomain)}
          </StyledSummaryText>
          <CaretRightBlack />
        </StyledButton>
      </StyledItem>

      <Form>
        <FormGroup>
          <h3>Data sources</h3>
        </FormGroup>
      </Form>

      <StyledItem onClick={() => null}>
        <Item.Meta
          title="Connection sharing"
          description="Allow users to share connections with other users in your domain(s)"
        />
        <StyledButton
          type="text"
          onClick={() => {
            setCurrentSettingType("connection_sharing_settings");
            setShowAdminSettingsConfigureModal(true);
          }}
        >
          <StyledSummaryText
            className={
              Boolean(adminExtSettings?.connection_sharing_settings?.is_disabled) ? "" : "underline"
            }
          >
            {getconfigSummaryText("connection_sharing_settings", adminExtSettings, isSingleDomain)}
          </StyledSummaryText>
          <CaretRightBlack />
        </StyledButton>
      </StyledItem>

      {Boolean(adminExtSettings?.show_allowed_data_sources_settings_in_ui) && (
        <StyledItem onClick={() => null}>
          <Item.Meta
            title="Allowed data sources"
            description="Allow users to only connect to specified data sources"
          />
          <StyledButton
            type="text"
            onClick={() => {
              setCurrentSettingType("allowed_data_sources_settings");
              setShowAdminSettingsConfigureModal(true);
            }}
          >
            <StyledSummaryText className="underline">
              {getconfigSummaryText(
                "allowed_data_sources_settings",
                adminExtSettings,
                isSingleDomain
              )}
            </StyledSummaryText>
            <CaretRightBlack />
          </StyledButton>
        </StyledItem>
      )}

      <StyledItem onClick={() => null}>
        <Item.Meta
          title="OAuth settings"
          description="Allow users to connect to Looker, NetSuite & Snowflake using OAuth"
        />
        <StyledLink
          to={{ pathname: `/dashboard/settings/oauth-config`, search: window.location.search }}
        >
          <StyledSummaryText>Manage</StyledSummaryText>
          <CaretRightBlack />
        </StyledLink>
      </StyledItem>

      {Boolean(clientFlags?.show_sso_config_ui) && (
        <>
          <Form>
            <FormGroup>
              <h3>Login & security</h3>
            </FormGroup>
          </Form>

          <StyledItem onClick={() => null}>
            <Item.Meta
              title="SSO authentication"
              description="Requires users to sign in with your SSO provider before using Coefficient"
            />
            <StyledLink
              to={{ pathname: `/dashboard/settings/configure-sso`, search: window.location.search }}
            >
              <StyledSummaryText>Configure</StyledSummaryText>
              <CaretRightBlack />
            </StyledLink>
          </StyledItem>
        </>
      )}

      <AdminSettingsConfigureModal
        visible={showAdminSettingsConfigureModal}
        onSuccess={() => message.success("asda")}
        onCancel={() => setShowAdminSettingsConfigureModal(false)}
        settingsType={currentSettingType}
      />
    </Content>
  );
};
