import { DefaultApi, LookerAppInfo, NetSuiteAppInfo, SnowflakeAppInfo } from "@coeff/api";
import { useQuery } from "@tanstack/react-query";
import { Dropdown, Menu, message } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  Form,
  FlexRow,
  ImportTypeIcon,
  List,
  LoaderWithPerfTimings,
  Typography,
} from "../components";
import { COLORS } from "../constants";
import { AppState } from "../store";
import { useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";

const Container = styled.div`
  padding: 25px 30px;
  max-width: 660px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHeader = styled(Header)`
  margin: 0;
  h1 {
    margin: 0;
  }
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 12px;

  h3 {
    font-weight: bold;
    margin: 12px 0;
  }
`;

const NoAppInfoContainer = styled.div`
  padding: 100px !important;
  justify-content: center;

  h3 {
    margin-bottom: 0;
  }
`;

const { Item } = List;

const DataSourceSelections = styled.div`
  border-radius: 6px;
  cursor: default !important;

  .ant-list-item {
    .actionswrap {
      visibility: visible !important;
      border-radius: 6px !important;
    }
  }
`;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black2};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;
    padding: 12px 12px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      margin: 12px 15px !important;
      border: 10px;
      background-color: ${COLORS.black4} !important;
      border-radius: 6px !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black45};
      line-height: 1.25;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black85};
      font-size: 14px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;

const StyledLink = styled(Link)`
  margin: 8px 16px;
`;

const StyledTypography = styled(Typography)`
  padding: 12px 4px;
`;

const StyledFlexRow = styled(FlexRow)`
  justify-content: center;
`;

const StyledNoConfigHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-select {
    width: 50%;
  }
`;

const getLookerbaseUrl = (url: string) =>
  url?.replace(/^https?:\/\//, "").replace(/(:\d+)?\/?$/, "");

const fetchLookerAppInfo = async (api: DefaultApi): Promise<LookerAppInfo[] | null> => {
  const response = await api.listLookerAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list;
  }
  return null;
};

const fetchNetSuiteAppInfo = async (api: DefaultApi): Promise<NetSuiteAppInfo[] | null> => {
  const response = await api.listNetSuiteAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list;
  }
  return null;
};

const fetchSnowflakeAppInfo = async (api: DefaultApi): Promise<SnowflakeAppInfo[] | null> => {
  const response = await api.listSnowflakeAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list;
  }
  return null;
};

export const OAuthConfigurations: React.FC = () => {
  const { clientFlags: clientFlags } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
    clientFlags: state.app.clientFlags,
  }));

  const [_, contextHolder] = message.useMessage();

  const track = useTrack();

  const { apiClient: api } = useApiContext();

  const fetchLookerAppInfoQuery = useQuery(
    [QueryCacheKey.LOOKER_APP_INFO],
    () => fetchLookerAppInfo(api),
    { cacheTime: 0 }
  );

  const {
    data: lookerAppInfos,
    isLoading: isFetchingLookerAppInfo,
    error: errorFetchingLookerAppInfos,
  } = fetchLookerAppInfoQuery;

  const fetchNetSuiteAppInfoQuery = useQuery(
    [QueryCacheKey.NETSUITE_APP_INFO],
    () => fetchNetSuiteAppInfo(api),
    { cacheTime: 0 }
  );

  const {
    data: netSuiteAppInfos,
    isLoading: isFetchingNetSuiteAppInfo,
    error: errorFetchingNetSuiteAppInfos,
  } = fetchNetSuiteAppInfoQuery;

  const fetchSnowflakeAppInfoQuery = useQuery(
    [QueryCacheKey.SNOWFLAKE_APP_INFO],
    () => fetchSnowflakeAppInfo(api),
    { cacheTime: 0 }
  );

  const {
    data: snowflakeAppInfos,
    isLoading: isFetchingSnowflakeAppInfo,
    error: errorFetchingSnowflakeAppInfos,
  } = fetchSnowflakeAppInfoQuery;

  useEffect(() => {
    document.title = "OAuth Settings - Coefficient Workspace";
    track("workspace_oauth_settings_viewed");
  }, []);

  const addAccountOptionsMenu = () => (
    <Menu>
      <Menu.Item>
        <Link to={`looker-oauth`}>{"Looker OAuth"}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`netsuite-oauth`}>{"NetSuite OAuth"}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`snowflake-oauth`}>{"Snowflake OAuth"}</Link>
      </Menu.Item>
    </Menu>
  );

  const renderAddAccountDropdown = (isLink: boolean) => {
    return (
      <DropdownWrapper>
        <Dropdown overlay={addAccountOptionsMenu()}>
          <Button type={isLink ? "link" : "default"} noPadding={isLink}>
            Add account
          </Button>
        </Dropdown>
      </DropdownWrapper>
    );
  };

  const renderContent = (children: React.ReactNode) => {
    return (
      <Container>
        <StyledHeaderContainer>
          <StyledHeader>
            <h1>OAuth Settings</h1>
          </StyledHeader>
          {renderAddAccountDropdown(false)}
        </StyledHeaderContainer>
        {contextHolder}
        {children}
      </Container>
    );
  };

  if (clientFlags?.is_gmail_like_domain) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Configuring OAuth accounts is not supported for your domain"
        description={`Configuring OAuth is not supported for ${
          clientFlags.email.split("@")[1]
        }. Please use a different account to configure OAuth accounts.`}
      />
    );
  }

  if (
    clientFlags?.admin_emails &&
    clientFlags?.admin_emails.length > 0 &&
    !clientFlags?.admin_emails?.includes(clientFlags.email)
  ) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="You need to be an admin to access this page"
        description="Please contact your domain admin for configuring or managing accounts."
      />
    );
  }

  if (isFetchingLookerAppInfo || isFetchingNetSuiteAppInfo || isFetchingSnowflakeAppInfo) {
    return renderContent(<LoaderWithPerfTimings name="AdminOAuthSettings" />);
  }

  if (
    errorFetchingLookerAppInfos ||
    errorFetchingNetSuiteAppInfos ||
    errorFetchingSnowflakeAppInfos
  ) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="An error occurred while loading the page"
        description="Please try refreshing the page after a while. If the issue persists, please contact support@coefficient.io."
      />
    );
  }

  const noAppInfos =
    (!lookerAppInfos || lookerAppInfos.length === 0) &&
    (!netSuiteAppInfos || netSuiteAppInfos.length === 0) &&
    (!snowflakeAppInfos || snowflakeAppInfos.length === 0);

  if (noAppInfos) {
    return renderContent(
      <NoAppInfoContainer>
        <StyledFlexRow>
          <StyledNoConfigHeader>No OAuth Accounts Configured</StyledNoConfigHeader>
        </StyledFlexRow>
        <StyledFlexRow>
          {renderAddAccountDropdown(true)}
          <StyledTypography color="textSecondary">for Looker, NetSuite, Snowflake</StyledTypography>
        </StyledFlexRow>
      </NoAppInfoContainer>
    );
  }

  return renderContent(
    <div>
      <Form>
        <FormGroup>
          <h3>Configured Accounts</h3>
        </FormGroup>
      </Form>

      {lookerAppInfos &&
        lookerAppInfos.map((appInfo, index) => (
          <DataSourceSelections key={index}>
            <StyledItem onClick={() => null}>
              <Item.Meta
                title="Base URL"
                avatar={<ImportTypeIcon type={"looker"} />}
                description={getLookerbaseUrl(appInfo.base_url)}
              />
              <StyledLink to={`looker-oauth?app_guid=${appInfo.client_app_guid}`}>
                Manage
              </StyledLink>
            </StyledItem>
          </DataSourceSelections>
        ))}

      {netSuiteAppInfos &&
        netSuiteAppInfos.map((appInfo, index) => (
          <DataSourceSelections key={index}>
            <StyledItem onClick={() => null}>
              <Item.Meta
                title="Account ID"
                avatar={<ImportTypeIcon type={"netsuite"} />}
                description={appInfo.account_id}
              />
              <StyledLink to={`netsuite-oauth/${appInfo.account_id}`}>Manage</StyledLink>
            </StyledItem>
          </DataSourceSelections>
        ))}

      {snowflakeAppInfos &&
        snowflakeAppInfos.map((appInfo, index) => (
          <DataSourceSelections key={index}>
            <StyledItem onClick={() => null}>
              <Item.Meta
                title="Account ID"
                avatar={<ImportTypeIcon type={"snowflake"} />}
                description={appInfo.account_id}
              />
              <StyledLink to={`snowflake-oauth/${appInfo.account_id}`}>Manage</StyledLink>
            </StyledItem>
          </DataSourceSelections>
        ))}
    </div>
  );
};
